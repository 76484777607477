import React from "react";
import SEO from "../components/seo";
import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
import "../css/ajuda.css";

function AjudaPage() {
    return (
        <>
            <SEO title="Ajuda" />
            <section className="pt-8 pb-32 px-12 lg:py-16 lg:px-6">
                <div className="container mx-auto text-azul_base ">
                    <div>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'center', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginBottom: '0cm', lineHeight: '100%'}}><strong>POLÍTICA DE PRIVACIDADE</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'center', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginBottom: '0cm', lineHeight: '150%'}}><strong>Itens 133, 133.6, 134 e 135 do Cap. XIII das NSCG/SP.</strong></p>
                        <br />
                        <br />
                        <p style={{marginBottom: '0cm', color: '#000000', lineHeight: '150%', textAlign: 'center', background: 'transparent', fontFamily: '"Calibri", serif', fontSize: '15px'}}><strong>(PROVIMENTO CGJ Nº 23/2020)</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>A Oficial de Registro Civil das Pessoas Naturais do 21º Subdistrito da Saúde, São Paulo, SP, respeita o direito à privacidade, à intimidade e o direito à proteção de dados pessoais e cumpre os preceitos da Lei n. 13.709, de 14 de agosto de 2018, Lei Geral de Proteção de Dados Pessoais – LGPD, e do Provimento n. 23, de 03 de setembro de 2020, da Corregedoria Geral da Justiça do Estado de São Paulo - Provimento CG 23/2020.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>No exercício de suas atribuições legais, a Oficial de Registro Civil das Pessoas Naturais do 21º Subdistrito da Saúde, São Paulo, SP, por meio de empresas de tecnologia compromissadas, realiza o tratamento de dados pessoais para cumprir sua finalidade pública, com o objetivo de executar as suas atribuições e competências legais do serviço público.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>A presente Política de Privacidade tem por objetivo informar as hipóteses em que - no exercício da competência legal desta Oficial de Registro Civil - são tratados dados pessoais, a sua finalidade, os procedimentos e as práticas utilizadas para a execução dessas atividades.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>Esta Política de Privacidade será atualizada sempre que necessário, motivo pelo qual recomenda-se sua constante e periódica consulta.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}><strong>TRATAMENTOS REALIZADOS</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>A Oficial de Registro Civil das Pessoas Naturais do 21º Subdistrito da Saúde, São Paulo, SP, é levada a tratar dados pessoais para cumprir as suas atribuições previstas na Lei 6.015/73, na Lei 8.935/95, na legislação tributária, nas Normas de Serviço Extrajudiciais da Corregedoria Geral da Justiça de São Paulo e normativas do Conselho Nacional de Justiça.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>O tratamento de dados pessoais consiste em toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>A coleta dos dados pessoais dos clientes deste cartório é feita com base nos documentos apresentados para confecção de registros e certidões, tais como: RG, CPF, certidões, declarações etc.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>Os dados pessoais são utilizados na redação dos assentos de registro civil passando a integrar os livros oficiais deste Oficial de Registro Civil, sendo armazenados em sistema informatizado, cuja providência é necessária para executar as competências e atribuições legais pertinentes.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}><strong>FINALIDADE DO TRATAMENTO</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>O tratamento de dados pessoais realizado pela Oficial de Registro Civil das Pessoas Naturais do 21º Subdistrito da Saúde, São Paulo, SP, tem por objetivo atender a sua finalidade pública, na persecução do interesse público, com o objetivo de executar as competências legais ou cumprir as atribuições legais e normativas do serviço público.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>Como finalidade específica destaca-se a execução dos serviços concernentes aos Registros Públicos, estabelecidos pela legislação para publicidade, autenticidade, segurança e eficácia dos atos jurídicos (art.1º da Lei n. 6.015/73 e art. 1º da Lei n. 8.935/94).</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}><strong>DA FORMA E DURAÇÃO DO TRATAMENTO</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>O tratamento de dados pessoais é realizado por meio de sistema de informática, sendo armazenados em bancos de dados informatizados, além de lançados nos livros oficiais.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>O tratamento se mantém durante o tempo necessário para a execução das atividades de registro civil, sendo que os assentos nos livros oficiais não são eliminados em cumprimento da sua finalidade pública prevista na legislação notarial.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}><strong>IDENTIFICAÇÃO DO CONTROLADOR</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>Os controladores dos dados do Oficial de Registro Civil das Pessoas Naturais do 21º Subdistrito da Saúde, São Paulo, SP, são VAGNER ROBERTO MALLIA II e VINICIUS LAZARII DE BARROS que podem ser contatados pelo e-mail: <a href="mailto:viniciuslazarini@cartoriosaude.com.br"><u><span lang="pt-BR">viniciuslazarini@cartoriosaude</span></u><u>.com.br</u></a>, ou pelo telefone +5517996488184; e Vagner no e-mail <u><a href="mailto:vagner.mallia@yahoo.com.br">vagner.mallia@yahoo.com.br</a></u> e telefone +5511982898014.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}><strong>COMPARTILHAMENTO DOS DADOS PESSOAIS</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>Os dados pessoais colhidos pela Oficial de Registro Civil das Pessoas Naturais do 21º Subdistrito da Saúde, São Paulo, SP, podem ser compartilhados com vista a atender disposições legais e normativas, por exemplo, com a Secretaria da Receita Federal, com o Conselho de Controle de Atividades Financeiras – COAF, com o Colégio Notarial do Brasil – CNB, com a Associação de Registradores Civis do Estado de São Paulo – ARPENSP, com a Associação de Registradores Civis do Brasil – ARPENBR, com o INSS/SIRC, com o IBGE/SEADE, com o Ministério da Defesa, com a Secretaria da Fazenda do Estado de São Paulo.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}><strong>RESPONSABILIDADES DOS AGENTES DE TRATAMENTO</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>Os agentes de tratamento de dados no âmbito desta serventia de registro civil de pessoas naturais são responsáveis por observar as disposições pertinentes à proteção de dados pessoais previstas na LGPD, bem como o direito à privacidade dos usuários do serviço.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}><strong>DIREITOS DOS TITULARES</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>O titular dos dados pessoais tem direito a obter deste Oficial de Registro Civil de Pessoas Naturais, em relação aos seus dados tratados, a qualquer momento e mediante requisição (art. 18, da LGPD):</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><em>I - confirmação da existência de tratamento;</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><em>II - acesso aos dados;</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><em>III - correção de dados incompletos, inexatos ou desatualizados;</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><em>IV - bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><em>V - portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial; &nbsp; &nbsp;</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><em>VI - eliminação dos dados pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da LGPD;</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><em>VII - informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados;</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><em>VIII - informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><em>IX - revogação do consentimento, nos termos do § 5º do art. 8º da LGPD.</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '150%'}}>O titular dos dados pessoais tem o direito de peticionar em relação aos seus dados contra o controlador perante a autoridade nacional.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '150%'}}>O titular pode opor-se a tratamento realizado com fundamento em uma das hipóteses de dispensa de consentimento, em caso de descumprimento ao disposto na LGPD.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '150%'}}>Os direitos serão exercidos mediante requerimento expresso do titular ou de representante legalmente constituído, ao agente de tratamento. Não sendo possível o atendimento imediato, será enviada resposta ao titular em que se poderá:</p>
                        <ol type="a">
                        <li>
                            <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}>comunicar que não é agente de tratamento dos dados e indicar, sempre que possível, o agente; ou</p>
                        </li>
                        </ol>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '2.75cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><br />&nbsp;</p>
                        <ol start={2} type="a">
                        <li>
                            <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}>indicar as razões de fato ou de direito que impedem a adoção imediata da providência.</p>
                        </li>
                        </ol>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}><br />&nbsp;</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '150%'}}>O requerimento será atendido sem custos para o titular, nos prazos e nos termos previstos em regulamento, exceção aos casos em que for prevista remuneração através de emolumentos legais.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.53cm', marginBottom: '0.53cm', lineHeight: '100%'}}>Conforme disposto no Provimento CGJ 23/2020:</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '100%'}}><em>141. Os titulares terão livre acesso aos dados pessoais, mediante consulta facilitada e gratuita que poderá abranger a exatidão, clareza, relevância, atualização, a forma e duração do tratamento e a integralidade dos dados pessoais.</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '100%'}}><em>142. O livre acesso é restrito ao titular dos dados pessoais e poderá ser promovido mediante informação verbal ou escrita, conforme for solicitado.</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '5cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '100%'}}><em>142.1 Na informação, que poderá ser prestada por meio eletrônico, seguro e idôneo para esse fim, ou por documento impresso, deverá constar a advertência de que foi entregue ao titular dos dados pessoais, na forma da Lei n. 13.709, de 14 de agosto de 2018, e que não produz os efeitos de certidão e, portanto, não é dotada de fé pública para prevalência de direito perante terceiros.</em></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '4cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '100%'}}><br />&nbsp;</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}><strong>UTILIZAÇÃO DOS DADOS PESSOAIS – CONTROLE DE FLUXO – REGISTRO DE TRATAMENTO</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>A Oficial de Registro Civil das Pessoas Naturais do 21º Subdistrito da Saúde, São Paulo, SP, utiliza os dados pessoais para o cumprimento de suas obrigações legais e normativas, conforme cada espécie de ato de registro civil praticado e está disposto em documento apartado denominado CONTROLE E MAPEAMENTO DE FLUXO DE DADOS PESSOAIS.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}><strong>POLÍTICA DE PRIVACIDADE NO SITE&nbsp;</strong><u><a href="http://WWW.CARTORIOTATUAPE.COM.BR/"><strong>WWW.CARTORIOSAUDE.COM.BR</strong></a></u><strong>, DE PROPRIEDADE DA OFICIAL DE REGISTRO CIVIL DAS PESSOAS NATURAIS DO 2</strong><strong>1</strong><strong>º SUBDISTRITO DA SAÚDE</strong>.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>O site <u><a href="http://www.cartoriotatuape.com.br/">www.cartoriosaude.com.br</a></u> utiliza ferramentas e serviços de terceiros que utilizam cookies. Estes são informações obtidas quando seu navegador acessa o site.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>São coletados dados pessoais apenas de usuários que desejam solicitar certidões de registro civil de pessoas naturais. Estes dados são coletados no site e enviados para e-mail do cartório, onde são utilizados para as emissões das certidões e os devidos envios. Os dados armazenados nas solicitações de certidões não são compartilhados, bem como não há transferência internacional destes dados.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'center', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}><strong>ENCARREGADO E CANAL DE ATENDIMENTO</strong></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', textIndent: '2cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '150%'}}>A Oficial de Registro Civil das Pessoas Naturais do 21º Subdistrito da Saúde, São Paulo, SP, possui um encarregado que atua como canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD), sendo ele VINICIUS LAZARINI DE BARROS, que pode ser contatado para fins de informações, reclamações ou sugestões pelo e-mail viniciuslazarini<u><a href="mailto:flavio@cartoriotatuape.com.br">@cartoriosaude.com.br</a></u>, site <u><a href="http://www.cartoriotatuape.com.br/">www.cartoriosaude.com.br</a></u> ou pelo telefone +55 17 99648-8184.</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginLeft: '4cm', marginTop: '0.49cm', marginBottom: '0.49cm', lineHeight: '100%'}}><br />&nbsp;</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginBottom: '0cm', lineHeight: '100%'}}>São Paulo, SP, 31 de julho de 2021</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginBottom: '0cm', lineHeight: '100%'}}><br /></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginBottom: '0cm', lineHeight: '100%'}}><br /></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginBottom: '0cm', lineHeight: '100%'}}><br /></p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginBottom: '0cm', lineHeight: '100%'}}>___________________________________________________________________</p>
                        <p style={{color: 'rgb(0, 0, 0)', textAlign: 'justify', background: 'transparent', fontFamily: 'Calibri, serif', fontSize: '15px', marginBottom: '0.28cm', lineHeight: '108%'}}>Oficial de Registro Civil das Pessoas Naturais do 21º Subdistrito da Saúde, São Paulo, SP, <span lang="pt-BR">GIOVANNA TRUFFI RINALDI</span></p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AjudaPage;
